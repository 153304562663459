<template>
  <div> 
    <br />
    <div class="row px-2">
      <div :class="errors != [] ? 'rounded bg-white border' : ''">
        <div v-for="(error, index) in errors" :key="index++" class="col-12">
          <div class="text-danger py-1">
            {{ index + " - " + error }}
          </div>
        </div>
      </div>
    </div>

    <form @submit.prevent="save(saleItem)">
      <div class="row align-items-end">
        <div class="col-xl-2 col-12 my-3">
          <label for="">Catégorie</label>
          <vue-select 
            :options="sections"
            v-model="section_reference"
            placeholder="Select an option"
            labelColumn="fullName"
            valueColumn="reference"
          />
        </div>
 
        <div class="col-xl-2 col-12 my-3">
          <label for="itemCodeInput"> Code </label>
          <vue-select 
          :options="filteredItems"
            @input="onItemCodeChangeSelected(itemCode)"
            v-model="itemCode"
            placeholder="Select an option"
            labelColumn="code"
            valueColumn="reference"
          />
        </div>

        <div class="col-xl-3 col-12 my-3">
          <label for="itemInput">Produit</label>

          <vue-select 
          :options="filteredItems"
            @input="onItemChangeSelected(item_reference)"
            v-model="item_reference"
            placeholder="Select an option"
            labelColumn="fullName"
            valueColumn="reference"
          />
        </div>

        <div class="col-xl-5 col-12 my-3">
          <label for="">Produit</label>
          <input :value="selectedItem.fullName" disabled class="form-control" />
        </div>

        <div class="col-xl-2 col-12 my-3">
          <label for=""> TVA </label>
          <input
            :value="selectedItem.tva + ' %'"
            disabled
            class="form-control"
          />
        </div>

        <div v-if="$userRole == 'Admin'" class="col-xl-2 col-12 my-3">
          <label for=""> Prix d'achat TTC </label>
          <input
            v-if="Object.keys(filteredStockItems).length == 1"
            :value="filteredStockItems[0].purchasePriceTTC + ' DIRHAMS'"
            disabled
            class="form-control"
          />
          <input v-else :value="' '" disabled class="form-control" />
        </div>

        <div class="col-xl-3 col-12 my-3">
          <label for=""> Stock <span class="text-danger">*</span> </label>
          <select
            v-if="Object.keys(filteredStockItems).length > 1"
            v-model="saleItem.stock_reference"
            class="form-select"
          >
            <option
              v-for="(stock, index) in filteredStockItems"
              :key="index"
              :value="stock.reference"
            >
              {{ stock.fullName }} Quantité : {{ stock.quantity }}
              {{ stock.measuring }}
            </option>
          </select>
          <input
            v-else-if="Object.keys(filteredStockItems).length == 1"
            type="text"
            disabled
            :value="
              filteredStockItems[0].fullName +
              ' ' +
              filteredStockItems[0].quantity +
              ' : ' +
              filteredStockItems[0].measuring
            "
            class="form-control"
          />

          <input
            v-else-if="
              Object.keys(filteredStockItems).length == 0 &&
              Object.keys(selectedItem) != ''
            "
            type="text"
            disabled
            :value="'Stock insuffisant'"
            class="form-control bg-danger text-danger border-danger"
          />

          <input
            v-else
            type="text"
            disabled
            :value="' '"
            class="form-control"
          />
        </div>

        <div class="col-xl col-6 my-3">
          <label for="">Quantité</label>
          <input
            v-model="saleItem.quantity"
            :disabled="
              Object.keys(filteredStockItems).length == 0 &&
              Object.keys(selectedItem) != ''
            "
            class="form-control"
            required
          />
        </div>

        <div class="col-xl col-6 my-3">
          <label for="">Prix TTC </label>
          <input required v-model="saleItem.priceTTC" class="form-control" />
        </div>

        <div class="col-xl col-12 my-3">
          <label for=""> Remarque </label>
          <input v-model="saleItem.remark" class="form-control" />
        </div>
      </div>

      <hr />
      <div class="row justify-content-end">
        <!-- <div class="col-xl-5 col-12">
          <label for=""> Importer à partir d'un commande client </label>
          <select v-model="order_reference" class="form-select">
            <option
              v-for="(order, index) in orders"
              :key="index++"
              :value="order.reference"
            >
              {{ order.date | date }}-
              <span v-if="order.customer">
                {{ order.customer.fullName }}-
              </span>
              {{ order.reference }}
            </option>
          </select>
        </div> -->

        <!-- <div class="col-xl col-12">
          <label for=""> </label>
          <button
            class="btn btn-warning d-flex align-items-center"
            @click="importData(order_reference)"
          >
            <i class="bi bi-upload fs-4 me-2"></i>
            <span> Importer </span>
          </button>
        </div> -->

        <div class="col-xl-auto col-6">
          <label for=""></label>
          <button class="btn btn-success shadow" type="submit">
            <i class="bi bi-plus-circle"></i> Insérer
          </button>
        </div>

        <div class="col-xl-auto col-6">
          <label for=""></label>
          <button
            type="button"
            class="btn btn-secondary d-flex align-items-center"
            @click="refresh()"
          >
            <i class="bi bi-arrow-clockwise fs-4 me-2"></i>
            <span> Actualiser </span>
          </button>
        </div>
      </div>
    </form>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Prix HT</th>
          <th scope="col">Prix TTC</th>
          <th scope="col">Quantité</th>
          <th scope="col">Total TTC</th>
          <th scope="col">Stock</th>
          <th scope="col">Remarque</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in saleItems" :key="index++">
          <td>
            {{ index }}
          </td>

          <td>
            <span v-if="item.item">
              {{ item.item.fullName }}
            </span>
          </td>

          <td class="">
            <span v-if="item.priceHT != null">
              {{ item.priceHT }}
            </span>
          </td>

          <td class="">
            <span v-if="item.priceTTC != null">
              {{ item.priceTTC }}
            </span>
          </td>

          <td class="">
            <span v-if="item.quantity != null">
              {{ item.quantity }}
            </span>
            <span v-if="item.item">
              {{ item.item.measuring }}
            </span>
          </td>
          <td class="">
            <span v-if="item.totalPriceTTC != null">
              {{ item.totalPriceTTC }}
            </span>
          </td>
          <td>
            <span v-if="item.stock">
              {{ item.stock.fullName }}
            </span>
          </td>
          <td>
            {{ item.remark }}
          </td>

          <td class="d-flex">
            <button @click="remove(item)" class="btn text-danger">
              <i class="bi bi-trash-fill"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="border-0">
          <td></td>

          <td>Total :</td>
          <td class=""></td>
          <td class=""></td>
          <td>
            <span class="fw-bold bg-warning px-2 py-1 rounded">
              {{  parseFloat(totalQuantityItems).toFixed(3) }}
            </span>
          </td>
          <td>
            <span class="fw-bold bg-warning px-2 py-1 rounded">
              {{ parseFloat(totalPriceTTCItems).toFixed(2) }}
            </span>
            DH
          </td>

          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-xl-8 col-12">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives aux articles de la vente (
            Quantité totale : <strong>{{ totalQuantityItems }}</strong> Kg,
            Mantant totale :
            <strong>
              {{ totalPriceTTCItems }}
            </strong>
            DH, Nombre d'articles :

            <strong>{{ numberItems }}</strong> ) et correctes.
            <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-xl-auto col-12">
        <button
          class="btn btn-primary"
          @click="next()"
          :disabled="!accept ? true : false"
          v-if="accept"
        >
          <i class="bi bi-arrow-right-circle"></i> Suivant
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      saleItem: {},
      section_reference: null,
      item_reference: null,
      itemCode: null,
      accept: false,
      customer_change: true,
      showError: false,
      errorFields: [],
      order_reference: "",
      selectedItem: {},
    };
  },
  computed: {
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("sale", {
      saleItems: "getItems",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
      errors: "getErrors",
    }),
    ...mapGetters("order", {
      orders: "getAll",
    }),
    ...mapGetters("stock", {
      stockItems: "getStockItems",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
    
    filteredStockItems() {
      if (this.selectedItem)
        return this.stockItems.filter(
          (stockItem) => stockItem.item_reference == this.selectedItem.reference
        );
      return null;
    },

    filteredItems() {
      if (this.section_reference == null) {
        return this.items;
      }

      return this.items.filter(
        (item) => item.section_reference == this.section_reference
      );
    },
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("sale/storeItem", data);
      await (this.saleItem.item_reference = "");
      await (this.saleItem.stock_reference = "");
      await (this.saleItem.quantity = "");
      await (this.saleItem.priceTTC = "");
      await (this.section_reference = null);
      await (this.item_reference = null);
      await (this.selectedItem = {});
      await this.$store.dispatch("stock/fetchStocksItems");
    },
    async remove(data) {
      let item = data.item;
      await this.$confirm({
        message: "Vous voulez supprimer l'article  " + item.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("sale/destroyItem", data.id);
          }
        },
      });
    },
    async refresh() {
      await this.$store.dispatch("stock/fetchStocksItems");
      this.$store.dispatch("section/getAll");
      this.$store.dispatch("item/getAll");
      this.$store.dispatch("sale/getAllItems");
      this.$store.dispatch("customer/getAll");
      this.$store.dispatch("order/getAll");
    },
    async next() {
      console.log(this.userRole);
      let order_reference = "";
      let customer_reference = this.saleItem.customer_reference;
      if (this.$route.query.order) order_reference = this.$route.query.order;
      if (this.$route.query.customer)
        customer_reference = this.$route.query.customer;

      this.$router.push({
        name: "sales-create",
        query: {
          customer_reference: customer_reference,
          order_reference: order_reference,
        },
      });
    },

    async importData(data) {
      let reference = data;
      this.$router.push({
        name: "sales-items-create",
        query: { customer: reference, order: reference },
      });
      await this.$confirm({
        message: "Vous voulez importer les articles de commande : " + reference,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("sale/uploadOrderItems", reference);
          }
        },
      });
    },

    async onItemChangeSelected() {
      await (this.saleItem = { priceTTC: 0.0, quantity: 1 });

      await (this.selectedItem = this.items.find(
        (p) => p.reference == this.item_reference
      ));

      await (this.saleItem.item_reference = this.selectedItem.reference);

      await console.log(this.item_reference);
      await console.log(this.items);
      // await console.log(this.selectedItem);
      if (this.selectedItem != null) {
        await (this.item_reference = "");
      }
      await console.log("out if", Object.keys(this.filteredStockItems).length);
      if (Object.keys(this.filteredStockItems).length == 1) {
        await (this.saleItem.stock_reference =
          this.filteredStockItems[0].reference);
        await console.log("in if", Object.keys(this.filteredStockItems).length);
        await console.log("in 2 if", this.saleItem.stock_reference);
      }
    },

    async onItemCodeChangeSelected() {
      await (this.saleItem = { priceTTC: 0.0, quantity: 1 });

      await (this.selectedItem = this.items.find(
        (p) => p.code == this.itemCode
      ));

      await (this.saleItem.item_reference = this.selectedItem.reference);

      await console.log(this.itemCode);
      await console.log(this.items);
      // await console.log(this.selectedItem);
      if (this.selectedItem != null) {
        await (this.itemCode = "");
      }
      await console.log("out if", Object.keys(this.filteredStockItems).length);
      if (Object.keys(this.filteredStockItems).length == 1) {
        await (this.saleItem.stock_reference =
          this.filteredStockItems[0].reference);
        await console.log("in if", Object.keys(this.filteredStockItems).length);
        await console.log("in 2 if", this.saleItem.stock_reference);
      }
    },
  },
  async created() {
    await this.$store.dispatch("stock/fetchStocksItems");
    await this.$store.dispatch("section/getAll");
    await this.$store.dispatch("item/getAll");
    await this.$store.dispatch("sale/getAllItems");
    await this.$store.dispatch("customer/getAll");
    await this.$store.dispatch("order/getAll");
  },
  watch: {},
  filters: {
    date: (value) => {
      return moment(value).format("DD-MM-YYYY");
    },
  },
};
</script>
